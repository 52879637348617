$ratio: .7;

.title {
  position: relative;
  text-align: center;
  @include text-colors();
  @include weights();

  h1, h2, h3, h4 {
    line-height: 1;
  }

  &--uppercase {
    h1, h2, h3, h4 {
      text-transform: uppercase;
    }
  }

  &--center {
    text-align: center;
  }
  &--desktop-center {
    @include media-breakpoint-up(xs-sm) {
      text-align: center;
    }
  }

  h1 {
    $size: 1.688125em;
    @include fluid-attr(font-size, $size * .8, $size);
    @include media-breakpoint-up(xs-sm) {
      $size: 2.875em;
      @include fluid-attr(font-size, $size * .5, $size);
    }
  }

  h2 {
    $size: 1.33333333333em;
    @include fluid-attr(font-size, $size * .8, $size);

    @include media-breakpoint-up(xs-sm) {
      $size: 2.5625em;
      @include fluid-attr(font-size, $size * .8, $size);
    }
  }

  h3 {
    $size: 1.875em;
    @include fluid-attr(font-size, $size, $size);
  }

  h4 {
    font-size: 15.96px;
    @include media-breakpoint-up(xs-sm) {
      $size: 1.8125em;
      @include fluid-attr(font-size, $size * .5, $size);
    }
  }

  h5 {
    $size: 1.25em;
    @include fluid-attr(font-size, $size, $size);
  }

  &--home-info {
    text-align: center;
    z-index: 0;

    h2 {
      font-weight: 600;
      display: inline;
      font-size: 1.2em;
      background-color: $color-white;
      color: $color-blue-800;
      padding: 0 0.3em;
      @include media-breakpoint-up(xs-sm) {
        $size: 2.5625em;
        @include fluid-attr(font-size, $size * .5, $size);
      }
    }

    &-second {
      z-index: 1000;
    }
  }

  &--main-stage {
    position: relative;

    img {
      position: absolute;

      &.top-right {
        right: 0;
        top: 0;
      }

      &.bottom-left {
        right: 0;
        top: 0;
      }
    }

    h1,
    h4 {
      display: inline;
      background-color: $color-blue-800;
      text-transform: uppercase;
      color: $color-white;
      $padding-y: .10em;
      $padding-x: .37em;
      @include fluid-attr(padding-top, $padding-y, $padding-y);
      @include fluid-attr(padding-bottom, $padding-y, $padding-y);
      @include fluid-attr(padding-left, $padding-x, $padding-x);
      @include fluid-attr(padding-right, $padding-x, $padding-x);

      span {
        color: $color-pink-200;
      }
    }
  }

  .flower {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: -1;

    &.z-10 {
      z-index: 10;
    }

    &-right-top {
      margin: -16% -11% auto auto;
    }

    &-right-top-tribe {
      margin: -5% 1% auto auto;
    }

    &-right-top-registration {
      margin: -10% -8% auto auto;
    }

    &-right-top-info {
      margin: -9% 4% auto auto;
    }

    &-left-bottom {
      margin: auto auto -15% -15%;
    }

    &-left-bottom-registration {
      margin: auto auto -7% -7%;
    }

    &-left-bottom-tribe {
      margin: auto auto -7% -7%;
    }

    &-left-bottom-info {
      margin: auto auto -10% -10%;
    }
  }
}
