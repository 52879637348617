.app {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow: auto;
}

.android {
  .title h4 {
    font-size: 12px !important;
  }

  p {
    font-weight: bold !important;
  }

  .button {
    font-size: 15px !important;
  }





  @media (max-width: 420px) and (max-height: 800px) {
    a.main-stage {
      left: 17% !important;
      top: 43% !important;
    }

    a.choose-your-tribe {
      left: 10% !important;
      top: 75% !important;
    }

    a.registration-and-experience {
      left: 55% !important;
      top: 87% !important;
    }

    a.info-centre {
      left: 53% !important;
      top: 50% !important;
    }

    .links-overlay {
      .main-stage {
        top: 30% !important;
      }
      .choose-your-tribe{
        top: 65% !important;
      }
      .registration-and-experience{
        top: 75% !important;
      }
      .info-centre{
        top: 40% !important;
      }

    }
  }

  @media (max-width: 420px) and (max-height: 725px) {
    a.main-stage {
      left: 17% !important;
      top: 38% !important;
    }

    a.choose-your-tribe {
      left: 12% !important;
      top: 73% !important;
    }

    a.registration-and-experience {
      left: 55% !important;
    }
  }

  @media (max-width: 380px) and (max-height: 680px) {
    a.main-stage {
      left: 15% !important;
      top: 30% !important;
    }

    a.choose-your-tribe {
      left: 10% !important;
      top: 65% !important;
      
    }

    a.registration-and-experience {
      left: 52% !important;
      top: 77% !important;
    }

    a.info-centre {
      left: 50% !important;
      top: 40% !important;
    }

    .links-overlay {
      .main-stage {
        top: 20% !important;
      }
      .choose-your-tribe{
        top: 55% !important;
      }
      .registration-and-experience{
        top: 65% !important;
      }
      .info-centre{
        top: 30% !important;
      }

    }

    .spacer--top-1em {
      margin-top: 1px !important;
    }

    .form {
      font-size: 12px;

      .spacer--top-1em {
        margin-top: 10px !important;
      }
    }
  }
}

