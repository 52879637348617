.page {
  position: relative;
  overflow: hidden;
  width: 100%;
  min-height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  .desktop-header {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    padding-top: 1em;
    img {
      max-width: 300px;
      margin: 0 auto;
    }
  }
  .desktop-footer {
    align-self: flex-end;
    width: 100%;
    height: auto;
    flex: 0;
    background-color: #988;
  }
  .desktop-footer {
    align-self: flex-end;
  }
  .desktop-body {
    margin: 0 auto;
    max-width: 829.4px;
  }
  .container {
    max-width: 300px;
    margin: 0 auto;
  }
  &--desktop {
    position: relative;
    width: 100%;

    h1 {
      justify-self: center;
      align-self: center;
      color: $color-primary;
      font-size: 3em;
    }
  }

  &--home {
    overflow: hidden;
    position: relative;
    background-color: $color-primary;

    .desktop-map {
      $margin-x: 10em;
      @include fluid-attr(margin-left, $margin-x * .5, $margin-x);
      display: flex;
      height: 100%;
      justify-content: center;
      flex-wrap: wrap;
      $margin-y: -5em;
      @include fluid-attr(margin-top, $margin-y * .5, $margin-y);

      &-image {
        $width: 110em;
        @include fluid-attr(width, $width * .4, $width);
        max-width: 1771px;
        height: auto;
      }
      &-links {
        position: absolute;
        height: 100%;
        width: 100%;
        left: 10em;
        display: flex;
        flex-wrap: wrap;

        &__link {
          padding: 0;
          cursor: pointer;
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          top: 0;

          &.main-stage {
            $height: 15em;
            $width: 20em;
            margin: 0 auto auto 22%;
            @include fluid-attr(height, $height * .2, $height);
            @include fluid-attr(width, $width * .2, $width);
            @media all and  (max-width: 1024px){
              margin: 0 auto auto 15%;
            }
          }

          &.info-centre {
            $height: 15em;
            $width: 20em;
            margin: 5% 40% auto auto;
            @include fluid-attr(height, $height * .2, $height);
            @include fluid-attr(width, $width * .2, $width);
          }
          &.choose-your-tribe {
            $height: 15em;
            $width: 20em;
            margin: 18% 70% auto 22%;
            @include fluid-attr(height, $height * .2, $height);
            @include fluid-attr(width, $width * .2, $width);
            @media all and  (max-width: 1024px){
              margin: 18% 70% auto 15%;
            }
          }

          &.registration-and-experience {
            $height: 15em;
            $width: 20em;
            margin: 20% 40% auto auto;
            @include fluid-attr(height, $height * .2, $height);
            @include fluid-attr(width, $width * .2, $width);
          }
        }
      }
    }

    .header {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      z-index: 100;
      width: 100%;
      &-title {
        margin-top: -1.8em;
      }

      &-logo {
        z-index: 100;
        @include media-breakpoint-up(xs-sm) {
          max-width: 300px;
        }
      }
    }

    .body {
      position: absolute;
      bottom: 2.5%;
      top: auto;
      width: 100%;
      right: -2%;

      @media (max-height: 680px ) {
        height: 85%;
      }
    }

    .hashtag {
      background-color: $color-pink-200;
      padding: 0.1em 0.5em;
      position: absolute;
      color: $color-blue-800;
      z-index: 400000000;
      bottom: 1%;
      left: 50%;
      transform: translateX(-50%);
    }

    .links {
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      position: absolute;
      z-index: 10000;

      &-overlay {
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        position: absolute;
        z-index: 200000;
        cursor: pointer;

        &-links {
          position: absolute;
          display: inline-block;
          width: 130px;
          height: 130px;
          background: rgba(000, 000, 000, 0);
        }

        .main-stage {
          top: 37%;
          left: 10%;
        }

        .info-centre {
          top: 47%;
          right: 14%;
        }

        .choose-your-tribe {
          top: 67%;
          left: 10%;
        }

        .registration-and-experience {
          top: 77%;
          right: 14%;
        }

        @media (min-width: 400px) and (min-height: 700px) {
          &-links {
            height: 130px;
          }

          .main-stage {
            top: 27%;
            left: 15%;
          }

          .info-centre {
            top: 36%;
            right: 17%;
          }

          .choose-your-tribe {
            top: 59%;
            left: 10%;
          }
          .registration-and-experience {
            top: 72%;
            right: 14%;
          }
        }

        @media (max-height: 670px) {
          &-links {
            height: 130px;
          }

          .main-stage {
            top: 24%;
            left: 10%;
          }

          .info-centre {
            top: 31%;
            right: 14%;
          }

          .choose-your-tribe {
            top: 57%;
            left: 10%;
          }
          .registration-and-experience {
            top: 70%;
            right: 14%;
          }
        }

        @media (max-height: 600px) {
          &-links {
            height: 100px;
          }

          .main-stage {
            top: 24%;
            left: 10%;
          }

          .info-centre {
            top: 36%;
            right: 14%;
          }

          .choose-your-tribe {
            top: 65%;
            left: 10%;
          }
          .registration-and-experience {
            top: 80%;
            right: 14%;
          }
        }
        }


      a {
        display: inline-block;
        position: absolute;
        background-color: $color-pink-200;
        color: $color-blue-800;
        font-size: 1em;
        padding-left: .2em;
        padding-right: .2em;
        text-align: center;
        max-width: 130px;

        &.main-stage {
          top: 35.5%;
          left: 20%;
        }
        &.info-centre {
          top: 47%;
          left: 53%;
        }
        &.choose-your-tribe {
          top: 72.5%;
          left: 14%;
        }
        &.registration-and-experience {
          top: 85%;
          left: 59.1%;
        }
        &.registration-and-experience-2 {
          top: 91.5%;
          left: 55%;
        }

        @media (min-height: 741px){
          &.main-stage {
            top: 38.5%;
            left: 20%;
          }
          &.info-centre {
            top: 50%;
            left: 53%;
          }
          &.choose-your-tribe {
            top: 73.5%;
            left: 14%;
          }
          &.registration-and-experience {
            top: 85%;
            left: 56.5%;
          }
        }

        @media (min-height: 781px){
          &.main-stage {
            top: 50.5%;
            left: 20%;
          }
          &.info-centre {
            top: 59%;
            left: 53%;
          }
          &.choose-your-tribe {
            top: 78.5%;
            left: 14%;
          }
          &.registration-and-experience {
            top: 88%;
            left: 56.5%;
          }
        }

        @media (max-width: 395px){
          &.registration-and-experience {
            left: 56.5%;
          }
        }

        @media (max-height: 600px){
          &.main-stage {
            top: 35.5%;
            left: 20%;
          }
          &.info-centre {
            top: 50%;
            left: 53%;
          }
          &.choose-your-tribe {
            top: 79.5%;
            left: 12%;
          }
          &.registration-and-experience {
            top: 94%;
            left: 55.1%;
          }
        }
      }
    }
  }
  &--main-stage,
  &--info-center,
  &--choose-your-tribe,
  &--registration {
    border: 20px solid rgba($color-primary, .9);

  }

  &--info-center,
  &--choose-your-tribe, {
    .header {

      img:first-child {
        //transform: translateX(20px);
      }
    }
  }
  &--main-stage {
    z-index: 1000;
    overflow-y: auto;
    border: 20px solid rgba($color-primary, .9);
    display: flex;
    height: 100%;
    justify-content: space-between;
    flex-direction: column;

    .header {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      & > img {
       width: 131px;
      }
    }

    .footer {
      @media(min-width: 500px) {
        margin-top: 2em;
      }
    }
  }

  &--info-center {
    .header {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      & > img {
        width: 131px;
      }
    }
    .inside-amazon {
      max-width: 122px;
      padding: .25em 0;
      margin: 0 auto;
      background-color: $color-blue-800;
    }
    .info {
      display: flex;
      justify-content: space-between;
      &-wrapper {
        max-width: 250px;
        margin: 0 auto;
      }
      &-icon {
        max-width: 57px;
        width: 100%;
        height: auto;
        margin-right: .5em;
        @include media-breakpoint-up(xs-sm) {
          max-width: 103.92px;
        }
        img {
          width: 100%;
          height: auto;
        }
      }
      &-content {
        border: 2px solid $color-pink-200;
        width: 100%;

        &-header {
          background-color: $color-pink-200;
          padding: 0 .5em;
          $size: 1.25em;
          @include fluid-attr(font-size, $size * .7, $size);
        }

        &-body {
          $size: .8em;
          @include fluid-attr(font-size, $size * .7, $size);
        }
      }
    }
  }

  &--choose-your-tribe {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    .header {
      display: flex;
      align-items: center;
      flex-direction: column;

      & > img {
        width: 131px;
      }
    }

    .info {
      display: flex;
      flex-direction: row;
      max-width: 223.09px;
      @include media-breakpoint-up(xs-sm) {
        max-width: 100%;
      }
      margin: 0 auto;
      div {
        background-color: $color-pink-200;
      }
      div:not(:last-child) {
        flex: 1;
        @include media-breakpoint-up(xs-sm) {
          flex: 3;
        }
      }
      div:last-child {
        flex: 1;
      }
      &-icon {
        @include media-breakpoint-up(xs-sm) {
          max-width: 100px;
          background-color: $color-blue-800 !important;
          display: flex;
          justify-content: flex-end;
          align-items: flex-end;
        }

        img {
          width: auto;
          height: 100%;
          max-width: 48.28px;
          @include media-breakpoint-up(xs-sm) {
            max-width: 100%;
            height: auto;
          }
        }
      }
      &-text {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      &-spotify {
        display: flex;
        height: auto;
        justify-content: flex-end;
        align-items: center;
        position: relative;
        overflow: hidden;

        &:before {
          position: absolute;
          content: "";
          left: 20%;
          height: 150%;
          top: -20%;
          right: 0;
          bottom: 0;
          z-index: 0;
          background-color: darken($color-pink-200, 3) !important;
          border-radius: 50% 0 0 50%;
          @include media-breakpoint-up(xs-sm) {
            left: 20%;
          }
        }

        img {
          z-index: 100;
          margin-right: 17px;
          width: 23px;
        }
      }
    }
  }

  &--registration {
    .header {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      & > img {
        width: 131px;
      }
    }

    .form-success {
      height: 100vh;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      @include media-breakpoint-up(xs-sm) {
        height: auto;
      }
      .footer {
        position: absolute;
        bottom: 0;
        width: 100%;
      }
    }

    .success {
      text-align: center;
      color: rgb(54, 194, 181);
    }

    .footer {
      display: flex;
    }

    .form {
      width: 100%;
      height: auto;
      max-width: 240px;
      margin: 0 auto;
      @include media-breakpoint-up(xs-sm) {
        //max-width: 1400px;
        width: 100%;
        max-width: unset;
        $padding: 15em;
        @include fluid-attr(padding-left, $padding * 0, $padding);
        @include fluid-attr(padding-right, $padding * 0, $padding);
      }


      input {
        width: 100%;
        height: auto;
        border: none;
        padding: 1em;
        font-size: 14px;
        background-color: rgba($color-pink-200, .30);
        color: $color-blue-800;
        @include media-breakpoint-up(xs-sm) {
          $size: 1.25em;
          @include fluid-attr(font-size, $size * .7, $size);
        }
        &::placeholder {
          text-transform: uppercase;
          font-size: 12px;
          font-weight: bold;
          color: rgba($color-blue-800, 0.4);
        }
      }

      .input-group {
        width: 100%;
        height: auto;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: .5em;
        background-color: $color-pink-100;

        &-wrapper {
          border: 2px solid $color-pink-100;
        }

        .checkbox {
          position: relative;
          margin-right: 1em;
          background-color: $color-white;
          width: 1.55375em;
          height: 1.55375em;
          display: flex;
          cursor: pointer;
          justify-content: center;
          align-items: center;
          input {
            position: absolute;
            width: 100%;
            cursor: pointer;
            z-index: 100;
            height: 100%;
            opacity: 0;
          }
          .checkbox-icon {
            opacity: 0;

            &.visible {
              opacity: 1;
            }
            &.hidden {
              opacity: 0;
            }
          }
        }
      }
    }
  }
}