.background {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 100000;
  pointer-events: none;
  img {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    @include media-breakpoint-up(sm) {
      $width: 23.875em;
      @include fluid-attr(max-width, $width * .5, $width);
    }
  }
}