.layout {
  $ratio: .5;
  margin: 0 auto;
  position: relative;
  flex-grow: 1;
  &--z-0 {
    z-index: 0;
  }
  @include child-flex-alignment();
  &--relative {
    position: relative;
  }

  &--spacing-1em {
    $spacing: 1em;
    @include fluid-attr(padding-left, $spacing * $ratio, $spacing);
    @include fluid-attr(padding-right, $spacing * $ratio, $spacing);
  }
  &--container-large {
    width: 100%;
    $padding: 15em;
    @include fluid-attr(padding-left, $padding * 0, $padding);
    @include fluid-attr(padding-right, $padding * 0, $padding);
  }

  &--flex {
    display: flex;
  }

  &--column {
    flex-direction: column;
  }

  &__row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    height: 100%;
    &--wrap-reverse {
      flex-wrap: wrap-reverse;
    }
    &--justify-center {
      justify-content: center;
    }
    &--spacing-1em {
      $spacing: 1em;
      @include fluid-attr(margin, -$spacing * $ratio, -$spacing);
    }
    &--spacing-\.5em {
      $spacing: 0.5em;
      @include fluid-attr(margin, -$spacing * $ratio, -$spacing);
    }
    &--spacing-2em {
      $spacing: 2em;
      @include fluid-attr(margin, -$spacing * $ratio, -$spacing);
    }
  }

  &__column {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    @include layout();
    @include child-flex-alignment();

    &--row {
      flex-direction: row;
    }

    &--spacing-1em {
      $spacing: 1em;
      @include fluid-attr(padding, $spacing * $ratio, $spacing);
    }
    &--spacing-\.5em {
      $spacing: 0.5em;
      @include fluid-attr(padding, $spacing * $ratio, $spacing);
    }

    &--spacing-2em {
      $spacing: 2em;
      @include fluid-attr(padding,  $spacing * $ratio, $spacing);
    }

    &--spacing-horizontal {
      $spacing: 0.5em;
      @include fluid-attr(padding-left, $spacing * $ratio, $spacing);
      @include fluid-attr(padding-right, $spacing * $ratio, $spacing);
    }
  }
}