.animate-on-scroll {
  opacity: 0;
  width: 100%;

  &.animation-default {
    animation: fade-in-with-scale;
    animation-fill-mode: forwards;
    animation-timing-function: ease-in-out;
    animation-duration: .7s;
    animation-delay: 0s;
  }

  &.animation-fade-in {
    animation: fade-in;
    animation-fill-mode: forwards;
    animation-timing-function: ease-in-out;
    animation-duration: .7s;
    animation-delay: 0s;
  }
  &.animation-fade-in-width {
    animation: fade-in-with-width;
    animation-fill-mode: forwards;
    animation-timing-function: ease-in-out;
    animation-duration: .7s;
    animation-delay: 0s;
  }
}

@keyframes fade-in-with-scale {
  from {
    transform: scale(.5);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fade-in-with-width {
  from {
    opacity: 0;
    width: 0;
  }
  to {
    opacity: 1;
    width: 100%;
  }
}
