.video {
  margin: 0;
  padding: 0;
  position: relative;
  background-color: #000000;
  &-icon {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }

  video {
    width: 100%;
    height: auto;
    margin: 0;
    padding: 0;
  }
}