$root-size: 16px;
$responsive-min: 16em;
$responsive-max: 110em;
$font-primary: BebasNeue, serif;
$grid-breakpoints: (
        xs: 0,
        xs-sm: 450px,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px,
) !default;

$color-primary: #36C2B5;
$color-pink-200: #FFCFCF;
$color-pink-100: #FFF0F0;
$color-blue-800: #005275;
$color-white: #fff;
$color-black: #000;
$weights: 100, 200, 300, 400, 500, 600;
$text-colors: (
        "color-primary": $color-primary,
        "color-blue-800": $color-blue-800,
        "color-pink-200": $color-pink-200,
        "color-white": $color-white
);
$background-colors: (
);