.button {
  margin: 0;
  border: none;
  outline: $color-primary;
  box-shadow: none;
  text-transform: uppercase;
  $spacing: 1em;
  font-family: "Bebas Neue", sans-serif;
  @include fluid-attr(padding-top, $spacing * .7, $spacing);
  @include fluid-attr(padding-bottom, $spacing * .7, $spacing);
  font-size: 21px;
  &--return {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $color-pink-200;
    color: $color-blue-800;
    width: 100%;
    font-weight: 400;
    img {
      margin-right: .5em;
    }
  }

  &--submit {
    background: $color-blue-800;
    color: $color-pink-200;
    width: 100%;
  }
}