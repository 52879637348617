.block {
  width: 100%;
  height: auto;
  display: block;
  &--justify-between {
    justify-content: space-between;
  }
  &--column {
    flex-direction: column;
  }
  &--full-height {
    height: 100%;
  }
  &--desktop-only {
    display: none;
    @include media-breakpoint-up(xs-sm) {
      display: block;
    }
  }
  &--desktop-flex {
    display: none;
    @include media-breakpoint-up(xs-sm) {
      display: flex;
    }
  }
  &--mobile-only {
    display: block;
    @include media-breakpoint-up(xs-sm) {
      display: none;
    }
  }
  &--mobile-flex {
    display: flex;
    @include media-breakpoint-up(xs-sm) {
      display: none;
    }
  }

  &--flex {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
