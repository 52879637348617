$ratio: .7;
.text {
  @include text-colors();
  @include weights();

  p {
    font-size: 1.25em;
  }
  &--xs {
    font-size: 13px;
    @include media-breakpoint-up(xs-sm) {
      font-size: 17px;
    }
  }

  &--desktop-center {
    @include media-breakpoint-up(xs-sm) {
      text-align: center;
    }
  }

  &--padding-\.5em {
    padding: .5em;
  }
  &--padding-1em {
    padding: 1em;
  }
  &--padding-2em {
    padding: 1em 2em;
  }
  &--small {
    font-size: 1.125em;
  }

  &--medium {
    $size: 1.25em;
    @include fluid-attr(font-size, $size * .8, $size);
  }

  &--uppercase {
    p {
      text-transform: uppercase;
    }
  }

  &--home {
    background-color: $color-pink-200;
    color: $color-blue-800;
  }
}